import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import Dialog from 'components/ui-components/Dialog';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { BricksComponentStore, BricksObject, CheckedBricks } from 'components/bricks/types/bricksComponentStore.type';
import { Brick, BrickOutputAction } from 'components/bricks/types/brick.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { MODEL_PUBLISH_DIALOG, MODEL_PUBLISH_DIALOG_PREVIEW_ID } from 'components/bricks/constants';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import PublishService from 'components/bricks/services/publish.service';
import DownloadHelpers from 'components/bricks/helpers/download.helpers';
import Translation from 'components/data/Translation';
import { filterBricksObjectByIds, filterBricksObjectByOutputAction } from 'components/bricks/helpers/brick-filters.helpers';
import SearchField from 'components/ui-components/SearchField/components';
import BrickDataService from 'components/bricks/services/brick-data.service';
import ComponentStore from 'components/data/ComponentStore';
import CustomBrickHelpers from 'components/bricks/helpers/custom-brick-helper';
import OutputHelper from 'components/bricks/helpers/output.helper';
import OutputTreeView from './components/tree-view';
import OutputDialogPreview from './components/preview';
import OutputDialogTabBar from './components/tabbar';

import './styles/main.scss';

interface ComponentStoreProps {
    validationErrors: BricksComponentStore['validationErrors'];
    bricks: BricksComponentStore['bricks'];
    checkedBricks?: CheckedBricks;
}

interface Props {
    brickIds: string[];
    outputAction: BrickOutputAction;
    onClose: () => void;
    onActionStart?: () => void;
    onActionFinish?: (publish: BrickPublishJobData[]) => void;
}

const OutputJobsDialog = ({ brickIds, outputAction, onClose, onActionStart, onActionFinish }: Props) => {
    const { bricks, checkedBricks: checkedBricksObject } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { bricks: 'bricks', checkedBricks: 'publishDialog.checkedBricks' }
    });

    const checkedBricks = useMemo(() => {
        if (!checkedBricksObject) return [];

        return Object.keys(checkedBricksObject);
    }, [checkedBricksObject]);

    if (!bricks) return null;

    /** This function is responsible for filtering the bricks that are selected and can be published. */
    const filterBricks = () => {
        const filteredBricks = filterBricksObjectByIds(bricks, brickIds); // Get only the selected brick and its parents
        return filterBricksObjectByOutputAction(filteredBricks, outputAction); // Filter only the bricks that can be published
    };

    const bricksObject = useMemo(() => filterBricks(), [bricks]);
    const bricksObjectLength = useMemo(() => Object.keys(bricksObject).length, [bricksObject]);
    const [filteredBricks, setFilteredBricks] = useState<BricksObject>(bricksObject);

    const handleConfirmDialog = async () => {
        switch (outputAction) {
            case 'publish':
                await handleConfirmPartialPublish();
                break;
            case 'download':
                await handleConfirmDownload();
                break;
        }
        onClose();
    };

    /**
     * Publish the selected Bricks (should maybe move to a services file?)
     */
    const handleConfirmPartialPublish = async () => {
        const bricksToPublish: Brick[] = OutputHelper.getBricksForOutput(checkedBricks);

        try {
            const response = await PublishService.publishBricks(bricksToPublish);

            if (response?.status === 'success' && response.publishJobs?.length && onActionStart) onActionStart();
        } catch {
            console.log('publishing failed');
        }
    };

    /**
     * Download assets from selected Bricks (should maybe move to a services file?)
     */
    const handleConfirmDownload = async () => {
        const bricksForOutput: Brick[] = OutputHelper.getBricksForOutput(checkedBricks);
        try {
            const result = await DownloadHelpers.downloadSelectedBricks(bricksForOutput);

            if (result?.status === 'success' && onActionFinish) onActionFinish(result.publishJobs || []);
        } catch {
            console.log('publishing failed');
        }
    };

    /**
     * Generates dialog confirm icon
     * @returns Dialog confirm icon
     */
    const getDialogConfirmIcon = () => {
        switch (outputAction) {
            case 'download':
                return 'download';
            case 'publish':
                return 'publish';
        }
    };

    const previewItems = useMemo(
        () =>
            Object.values(filteredBricks).map((brick) => {
                return { ...brick, title: BrickHelpers.getBrickTitle(brick.id, brick) };
            }) || [],
        [filteredBricks]
    );

    const handleFirstPreviewItem = (bricks: BricksObject) => {
        const firstBrick = Object.values(bricks)[0];
        if (firstBrick) ComponentStore.setModel('Bricks', MODEL_PUBLISH_DIALOG_PREVIEW_ID, firstBrick.id); // Set the first brick as the preview item
    };

    const handleOnBricksFilter = async (searchTerm: string) => {
        if (!searchTerm) {
            handleFirstPreviewItem(bricksObject);
            setFilteredBricks(bricksObject);
            return;
        }

        const noneCustomBricks = CustomBrickHelpers.filterOutCustomBricks(bricksObject);
        const topBrickIds = Object.keys(noneCustomBricks).map((key) => bricksObject[key].id);

        const bricks = (await BrickDataService.getBricksOverviewAsObject({ searchTerm, topBrickIds })) || {};

        handleFirstPreviewItem(bricks);
        setFilteredBricks(bricks);
    };

    const getDialogSubText = () => {
        if (bricksObjectLength === 1) return '';

        if (checkedBricks.length === 1) return `${checkedBricks.length} ${Translation.get('selectedItem', 'bricks')}`;
        return `${checkedBricks.length} ${Translation.get('selectedItems', 'bricks')}`;
    };

    const getConfirmText = () => {
        if (checkedBricks.length === 1) return Translation.get(`output.${outputAction}.outputItem`, 'bricks');
        return Translation.get(`output.${outputAction}.outputItems`, 'bricks');
    };

    useEffect(() => {
        return () => ComponentStore.removeItem('Bricks', MODEL_PUBLISH_DIALOG); // Reset the publish dialog state
    }, []);

    return (
        <Dialog
            classes={{ content: 'output-jobs-dialog-mui__content' }}
            title={Translation.get(`output.${outputAction}.title`, 'bricks')}
            onClose={() => onClose()}
            open={true}
            onConfirm={handleConfirmDialog}
            showCancel={true}
            maxWidth={'xl'}
            confirmText={getConfirmText()}
            confirmIcon={<Icon>{getDialogConfirmIcon()}</Icon>}
            confirmButtonDisabled={checkedBricks.length === 0}
            tooltip={checkedBricks.length === 0 ? Translation.get('output.selectAnItemOrCheckValidation', 'bricks') : ''}
            subText={getDialogSubText()}>
            <div className="output-jobs-dialog">
                <div className="output-jobs-dialog__content">
                    <div
                        className={classNames('output-jobs-dialog__content__tree', {
                            'output-jobs-dialog__content__tree-hidden': bricksObjectLength === 1
                        })}>
                        <OutputDialogTabBar>
                            <SearchField
                                className={'output-jobs-dialog__content__tree__search'}
                                onChange={(_, searchTerm) => handleOnBricksFilter(searchTerm)}
                                searchPlaceholder={Translation.get(`output.${outputAction}.searchPlaceholder`, 'bricks')}
                            />
                        </OutputDialogTabBar>
                        <OutputTreeView bricks={filteredBricks} />
                    </div>
                    <div
                        className={classNames('output-jobs-dialog__content__preview', {
                            'output-jobs-dialog__content__preview-only': bricksObjectLength === 1
                        })}>
                        <OutputDialogPreview bricks={previewItems} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default OutputJobsDialog;
